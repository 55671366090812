import React from "react";
import styled from "styled-components";
import { breakpoints, imgix } from "../../styles/variables";
import { Background } from "react-imgix";

const Bild = styled(Background)`
  width: 180px;
  height: 180px;
  margin: 0 auto 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin: 0;
    width: 204px;
    height: 100%;
  }
`;

const BildStatic = styled.div`
  width: 180px;
  height: 180px;
  margin: 0 auto 20px;
  background: url("/assets/gestaltungselemente/menu-item.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin: 0;
    width: 204px;
    height: 100%;
  }
`;

interface ItemBildProps {
  image: string;
}

const ItemBild: React.FC<ItemBildProps> = ({ image, imgx }) =>
  imgx ? <Bild src={image} imgixParams={imgix.small} /> : <BildStatic />;

export default ItemBild;
