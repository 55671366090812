import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import ItemBild from "./MenuItem/ItemBild";

const Item = styled.article`
  padding: 30px;
  background: ${colors.grey};

  * {
    text-align: center;
  }

  .date {
    font: 16px / 16px "RC Regular";
    letter-spacing: 1.6px;
    margin-bottom: 14px;
  }

  h3 {
    font: 24px / 34px "RC Bold";
    letter-spacing: 1.2px;
    margin-bottom: 14px;
  }

  p {
    font: 14px / 20px "RC Regular";
    letter-spacing: 1.4px;
    margin-bottom: 19px;
  }

  .price {
    font: 24px / 34px "RC Regular";
    letter-spacing: 1.2px;
    margin-bottom: 0;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    padding: 0;
    display: grid;
    grid-template-columns: 204px 1fr;
    margin-bottom: 30px;

    > div:last-of-type {
      padding: 20px 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    * {
      text-align: left;
    }

    .date {
      font: 20px / 20px "RC Regular";
      letter-spacing: 2px;
    }

    h3 {
      font: 28px / 40px "RC Bold";
      letter-spacing: 1.4px;
    }

    p {
      font: 16px / 22px "RC Regular";
      letter-spacing: 1.6px;
      margin-bottom: 27px;
    }

    .price {
      font: 28px / 40px "RC Regular";
      letter-spacing: 1.4px;
    }
  }
`;

interface MenuItemProps {
  entry: {
    gericht: string;
    beschreibung: string;
    preis: string;
    datum: string;
    bild: [{ url: string }];
    menuTyp: string;
  };
}

const MenuItem: React.FC<MenuItemProps> = ({ entry }) => {
  const datum = new Date(entry.datum);

  const dateOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
  };

  return (
    <Item>
      <ItemBild
        image={entry.bild.length > 0 ? entry.bild[0].url : ""}
        imgx={entry.bild.length > 0 ? true : false}
      />
      <div>
        <p className="date">
          {datum.toLocaleDateString("de-CH", dateOptions)},{" "}
          {entry.menuTyp === "vegimenu"
            ? "Vegimenü"
            : entry.menuTyp === "tagesmenu"
            ? "Tagesmenü"
            : "Wochenhit"}
        </p>
        <h3>{entry.gericht}</h3>
        <p>{entry.beschreibung}</p>
        {entry.preis ? <p className="price">Fr. {entry.preis}</p> : ""}
      </div>
    </Item>
  );
};

export default MenuItem;
