import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styles/variables";

const Button = styled.a`
  display: block;
  width: 152px;
  height: 152px;
  background: url("/assets/gestaltungselemente/download-button-white.svg");
  background-size: 152px 152px;
  margin-bottom: 56px;

  &.center {
    margin: 0 auto 56px;
  }

  &:hover {
    background: url("/assets/gestaltungselemente/download-button-orange.svg");

    background-size: 152px 152px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: 184px;
    height: 184px;
    background-size: 184px 184px;
    margin-bottom: 74px;

    &.center {
      margin: 0 auto 120px;
    }

    &.mobileOnly {
      display: none;
    }

    &:hover {
      background-size: 184px 184px;
    }
  }
`;

interface DownloadButtonProps {
  center?: boolean;
  mobileOnly?: boolean;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  center,
  mobileOnly,
  downloadUrl,
}) => (
  <Button
    href={downloadUrl}
    target="_blank"
    className={
      center && mobileOnly
        ? "center mobileOnly"
        : center
        ? "center"
        : mobileOnly
        ? "mobileOnly"
        : ""
    }
  />
);

export default DownloadButton;
