import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import DefaultLayout from "../layouts/default";
import DownloadButton from "../components/DownloadButton";
import MenuItem from "../components/MenuItem";
import Menu from "../components/Menu";
import Title from "../components/Title";

interface MenuQueryProps {
  data: {
    cms: {
      menu: {
        title: string;
        beschreibung: string;
        bistroNummer: string;
        titelMitOrangeBold: string;
        menuMatrix: [
          {
            bild: [
              {
                url: string;
              }
            ];
            gericht: string;
            datum: string;
            beschreibung: string;
            preis: string;
            menuTyp: string;
          }
        ];
      };
    };
  };
}
export const MenuQuery = graphql`
  query ($uri: String!) {
    cms {
      menu: entry(uri: [$uri]) {
        title
        ... on CMS_main_menu_Entry {
          beschreibung
          bistroNummer
          titelMitOrangeBold
          menuMatrix {
            ... on CMS_menuMatrix_gericht_BlockType {
              bild {
                url
              }
              gericht
              datum
              beschreibung
              preis
              menuTyp
            }
          }
        }
      }
      data: entry(type: "data") {
        ... on CMS_data_data_Entry {
          menuePdfMatrix {
            ... on CMS_menuePdfMatrix_wochenmenue_BlockType {
              menue {
                url
              }
              gueltigBis
            }
          }
        }
      }
    }
  }
`;

const MenuPage: React.FC<MenuQueryProps> = ({ data }) => {
  const [aktuellesMenu, setAktuellesMenu] = useState([]);
  const [aktuellesMenuPDF, setAktuellesMenuPDF] = useState(false);

  useEffect(() => {
    const now = new Date(Date.now());

    const menus = data.cms.data.menuePdfMatrix;

    const aktuell = menus.filter((item) => {
      const itemDate = new Date(item.gueltigBis);
      if (itemDate.getDate() >= now.getDate()) {
        return item;
      }
    });

    setAktuellesMenu(aktuell[0]);
    //gültig bis before now && closest to now?
  }, []);

  useEffect(() => {
    const today = new Date(Date.now());
    today.setHours(0, 0, 0, 0);
    const filteredMenu = data.cms.menu.menuMatrix.filter((item) => {
      const itemDate = new Date(item.datum);
      if (itemDate >= today) {
        return item;
      }
    });
    setAktuellesMenu(filteredMenu);

    //----- PDF Menu

    const menus = data.cms.data.menuePdfMatrix;

    const aktuell = menus.filter((item) => {
      const itemDate = new Date(item.gueltigBis);
      if (itemDate.getDate() >= today.getDate()) {
        return item;
      }
    });

    setAktuellesMenuPDF(aktuell[0]);
  }, []);

  return (
    <DefaultLayout
      beschreibung={data.cms.menu.beschreibung}
      titel={data.cms.menu.title}
    >
      {/* <MenuButtons bistroNummer={data.cms.menu.bistroNummer} /> */}
      <Title text={data.cms.menu.titelMitOrangeBold} topOffset />
      <Menu>
        {aktuellesMenu.length > 0
          ? aktuellesMenu.map((entry) => <MenuItem entry={entry} />)
          : ""}
      </Menu>
      {aktuellesMenuPDF ? (
        <DownloadButton downloadUrl={aktuellesMenuPDF.menue[0].url} center />
      ) : (
        ""
      )}
    </DefaultLayout>
  );
};

export default MenuPage;
