import React from "react";
import styled from "styled-components";
import { breakpoints, spacers } from "../styles/variables";

const MenuWrapper = styled.div`
  padding: 0 ${spacers.wrapper.mobile}px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  margin-bottom: 55px;

  @media screen AND (min-width: 640px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: block;
    padding: 0;
  }
`;

const Menu: React.FC = ({ children }) => <MenuWrapper>{children}</MenuWrapper>;

export default Menu;
